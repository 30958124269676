export const routeData = [
    {
        id: 1,
        route: 'KADAPAKKAM - Marakkanam',
        stops : ["Vennangupattu","Marakkanam", "Kandadu", "Alathur koot Road", "Murukeri", "Alanguppam", "Brammadesam", "Perumukkal", "Guruammapettai", "Endiyur", "Tindivanam (Housing Board)"]
    },
    {
        id: 2,
        route: 'KOONIMEDU - Jipmer',
        stops : ["Koonimedu","Keezhputtupattu","Kalapet","Sivaji Statue","Latha Steel","Jipmer"]
    },
    {
        id: 3,
        route: 'VILLIYANUR - Kootroad',
        stops : ["Pudhukadai bus stop", "Karikalampakkam",  "Villiyanur (Kottaimedu)","Mettupalaym", "Indian Coffee House", "Pattanur Check post", "Morattandi", "T C Kootroad", "Weighing Machine stop","Vanur Bus stop", "Ranganathapuram", "V.Parangani", "Thollamur Bust Stop"]
    },
    {
        id: 4,
        route: 'KALAMARUDUR - Thiruvennainallur',
        stops : ["Kalamarudur","Periyasevalai","Thiruvennainallur", "Emappur", "Enathimangalam", "Govindapuram", "Vazhudareddy"]
    },
    {
        id: 5,
        route: 'THIRUKOVILUR - Mambalapattu',
        stops : ["Thirukovilur","Arakandanallur","Mugaiyur","Chennakunnam","Mambalapattu", "Kanai", "Perumbakkam", "Villupuram"]
    },
    {
        id: 6,
        route: 'ACHARAPAKKAM - Tindivanam',
        stops :["Acharapakkam","Thozhupedu","Pathiri","Olakkur","Salavathy","Tindivanam"]
    },
    {
        id: 7,
        route: 'SATHIYAMANGALAM - Gingee',
        stops : ["Sathiyamangalam", "Alampoondi", "Gingee", "Peramandur"]
    },
    {
        id: 8,
        route: 'PANRUTI - Vikravandi',
        stops : ["Panruti GH","Paruti Signal", "Iswarya Bakery", "RK Mahal", "Panruti EB", "Weigh Bridge", "Poongunam", "Chithiraichavadi", "Koliyanur", "Vikravandi by pass"]
    },
    {
        id: 9,
        route: 'NEYVELI - Vikravandi',
        stops : ["Neyveli TS - Block 4", "Neyveli Arch", "Panruti Arch", "Kandarakottai", "Koliyanur Croos Road", "Vikravandi Toll"]
    },
    {
        id: 10,
        route: 'THIRUKANUR - Vikravandi',
        stops : ["Thirukkanoor","Machurapakkam","Radhapuram", "Panayapuram", "Vikiravandi Tollgate"]
    },
    {
        id: 11,
        route: 'THELLAR - Santhaimedu',
        stops : ["Thellar","Thellar Bus stop","Maavalavadi Junction","Therasapuram", "Katteri Bus stop", "Vellimedupettai", "Mavilangai", "Vinayakapuram", "Neykuppi", "Pattanam", "Santhaimedu", "Commitee stop", "Theerthakulam", "MRS gate stop", "Tindivanam-Mailam Bus stop"]
    }   ,
    {
        id: 12,
        route: 'CHETPET - Gingee',
        stops : ["Chetpet","Kuduvampoondi","Valathi", "Mahadevi Mangalam","Chellaperrati","Gingee", "Nattarmangalam", "Deevanur", "PV Polytechnic", "Tindivanam-MRS Stop"]
    },
    {
        id: 13,
        route: 'VILLUPURAM - NEWBUSSTAND',
        stops : ["Janakipuram", "TPTC Quartres", "TPTC Office", "Veeran kovil", "Villupuram new Bus Stand"]
    },
    {
        id: 14,
        route: 'SITHALINGAMADM - Vikravandi',
        stops : ["Sithalingamadam", "Thiruvennainallur", "Arasur", "Perangiyur", "Villupuram", "Vikravandi"]
    },
    {
        id: 15,
        route: 'VILLUPURAM - MADHAKOVIL',
        stops : ["Madhakovil","Junction", "Old Bus Stand"]
    },
    {
        id: 16,
        route: 'KANDACHIPURAM - Vikravandi',
        stops : ["Kandachipuram", "Surapattu", "EB", "Villupuram", "Chinthamani", "Mundiyabakkam", "Vikkravandi"]
    }
]